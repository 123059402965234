<template>
  <div>
  </div>
</template>

<script>
import { APPID, REDIRECT_URI_Login } from "@/config";

import { clearStore, removeStore, getStore } from "../../lib/storage";
export default {
  name: "inviteoauth",
  methods: {
    oauth(invitecode) {
      //   return;
      const appid = APPID;
      const redirect_uri = REDIRECT_URI_Login;
      const SCOPE = "snsapi_userinfo";
      // let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}&response_type=code&scope=${SCOPE}&state=${invitecode}&connect_redirect=1#wechat_redirect`;
      window.location.href = url;
      console.log(url);
      //   let abc = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${appid}&secret=${redirect_uri}&code=CODE&grant_type=authorization_code`;
    },
  },
  mounted() {
    // clearStore();
    if (getStore("startAddress")) {
      removeStore("startAddress");
    }
    let idcode = this.$route.params.idcode;
    this.oauth(idcode);
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.oauthBtn {
  .size(200px, 34px);
  margin: 30px auto;
  .bg(@brand-green);
  .color(#fff);
  .fontSize(32px);
  line-height: 34px;
  text-align: center;
}
</style>

